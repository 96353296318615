.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #a7adb3;
  }
  
  .table th,
  .table td {
    padding:.75rem;
    vertical-align: top;
    border-top: 1px solid #eee;
  }
  
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    /* background-color: #75b6fa; */
  }
  
  .table tbody + tbody {
    border-top: 2px solid #eee;
  }
  
  .table-primary {
    background-color: #eee;
  }
  
  .table-primary th{
    color: #01172e;
  }
  .table-primary td,
  .table-primary thead th {
    color: #004085;
  }
  
  .table-hover tbody tr:hover {
    background-color: #adb5bd;
  }

  .text-right {
    text-align: right;
  }
  .card_text {
    font-size: 1.5rem;
    color: #777;
    text-align: right;
  }
 

  .modalContentForadminHospital {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100% ;
    height: 90%;
    background-color: white;
    border-radius: 10px;
    /* box-shadow: 24px; */
    padding: 16px;
    overflow: scroll;
}
