.form-label{
    color:whitesmoke;
}

.actionButtons{
    text-align: right;
}


.actionButtons Button {
    margin-left:20px;
}

.error_msg,
.success_msg {
	width: 370px;
	padding: 15px;
	margin: 5px 0;
	font-size: 14px;
	background-color: #f34646;
	color: white;
	border-radius: 5px;
	text-align: center;
}
.customButton {
    color: #2E718A; /* Change this to the desired color */
}