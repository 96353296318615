.sidebar-list {
    list-style: none;
    display: flex;
    align-items: center;
    color:  "#f5f6fa" ; 
   
  }
  
.sidebar-list-item {
    margin-right: 10px;
    display: flex;
    align-items: center;
    background-color:  "#f5f6fa" ;
  }
  
.sidebar-list-item a {
    text-decoration: none;
    color: black; 
  }
  
.sidebar-list-item a span {
    margin-left: 5px;
  }
  
  .separator {
    margin: 0 5px;
  }

  