@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.main-cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 20px;
  padding: 10px;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.main-cards::-webkit-scrollbar {
  display: none; /* For WebKit browsers (Chrome, Safari) */
}

.cardnew {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  min-width: 200px;
  flex-shrink: 0;
}

.cardnew h1 {
  font-size: 2rem;
  margin: 0;
}

.cardnew span {
  border-radius: 10px;
  padding: 5px 15px;
  font-weight: bold;
  font-size: 1rem;
}
