.form-label {
  color: gray;
}

.actionButtons {
  text-align: right;
}

.actionButtons Button {
  margin-left: 20px;
}

.button-row {
  justify-content: flex-end;
}

.button-spacing {
  width: 20px; /* Adjust the width as needed for desired spacing */
}

.error_msg,
.success_msg {
  width: 370px;
  padding: 15px;
  margin: 5px 0;
  font-size: 14px;
  background-color: #f34646;
  color: white;
  border-radius: 5px;
  text-align: center;
}
.customButton:hover {
  background-color: #c45516;
  color: white;
}
#upc {
  background-color: white;
}
