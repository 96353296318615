.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .forgot-password-form {
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    width: 400px;
  }
  
  .text-danger {
    color: red;
  }
  
  .btn-lg1234 {
    width: 100%;
    padding: 10px;
    background-color: #2E718A;
    color: white;
  }
  
  .btn-lg1234:hover {
    background-color: #c45516;
  }
  
  h2 {
    font-weight: bold;
    color: #2E718A;
  }
  